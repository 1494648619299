import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import whatsLegalHeaderLogo from "../assets/WhatsLegal_logo_neu_2.png";
import { FaBars, FaTimes } from 'react-icons/fa';
import './css/Header.css'; 

const Header: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className="mainHeader">
      <div className="logoContainer">
        <Link to="/" onClick={() => setIsNavOpen(false)}>
          <img src={whatsLegalHeaderLogo} className="websiteLogo" alt="WhatsLegal.ai Logo" />
        </Link>
        {/* <span className="tagline">KI ASSISTENT FÜR RECHT<br/>KOSTENLOS - FÜR ALLE</span> */}
      </div>
      <nav className={`nav ${isNavOpen ? 'nav-open' : 'nav-close'}`}>
        <Link to="/" className="nav-item" onClick={() => setIsNavOpen(false)}>Free legal chatbot</Link>
        <Link to="/whitelabel" className="nav-item" onClick={() => setIsNavOpen(false)}>White Label</Link>
        <Link to="/about" className="nav-item" onClick={() => setIsNavOpen(false)}>About</Link>
        <Link to="/faqs" className="nav-item" onClick={() => setIsNavOpen(false)}>FAQs</Link>
      </nav>
      <button className="nav-toggle" onClick={toggleNav}>
        {isNavOpen ? <FaTimes /> : <FaBars />}
      </button>
    </div>
  );
}

export default Header;
