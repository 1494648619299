import React, { ChangeEvent, FormEvent, useEffect, useRef, useState, KeyboardEvent, MouseEvent } from "react";
import {
  filterHistory,
  getErrorMessage,
  getFormattedTime,
} from "../helpers/utils";
import { Modal } from "react-bootstrap";
import {
  FilteredHistory,
  HistoryObj,
  Message,
  Messages,
  MessagesList,
} from "../helpers/types";
import {
  BASE_URL,
  LANDING_PARA,
  LOGIN_ENDPOINT,
  SIGNUP_ENDPOINT,
} from "../helpers/constants";
import { LinearProgress, Tooltip } from "@mui/material";
import likeActive from "../assets/images/feedback/like_active.svg";
import likeInactive from "../assets/images/feedback/like_inactive.svg";
import neutralActive from "../assets/images/feedback/neutral_active.svg";
import neutralInactive from "../assets/images/feedback/neutral_inactive.svg";
import dislikeActive from "../assets/images/feedback/dislike_active.svg";
import dislikeInactive from "../assets/images/feedback/dislike_inactive.svg";
import { toast } from "react-toastify";
import axios, { CancelTokenSource } from "axios";
import { isEmpty } from "lodash";
import ReactMarkdown from "react-markdown";
import ReactGA from "react-ga4";
import whatslegalIcon from "../assets/Logos_WhatsLegal_250x250.png";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from '../firebase';
import { Article, Co2Sharp } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PlayArrow from "@mui/icons-material/PlayCircleOutline";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { IoClose } from "react-icons/io5";

import whatsLegalLogo from "../assets/whatslegalnewlogotransparent.png";
import './css/Popup.css';
import './css/ChatFrame.css';
import placeHolderAddImage from "../assets/avin.jpeg";
import PaypalButton from "./PaypalButton";
import WLIcon from "../WLIcon";

type Law = "fed" | "bayGe" | "bayVe";

const ChatBot: React.FC = () => {
  const [messages, setMessages] = useState<Messages>(
    JSON.parse(sessionStorage.getItem("messages") ?? "{}")
  );
  const [messagesList, setMessagesList] = useState<MessagesList>(
    JSON.parse(sessionStorage.getItem("messagesList") ?? "{}")
  );
  const [messageToggle, setMessageToggle] = useState(false);
  const [messageCompleted, setMessageCompleted] = useState(true);
  const [messageID, setMessageID] = useState("");
  const [chatInput, setChatInput] = useState<string>("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(true);
  const [mobileCollapsed, setMobileCollapsed] = useState(true);
  const [positiveChecked, setPositiveChecked] = useState(false);
  const [negativeChecked, setNegativeChecked] = useState(false);
  const [neutralChecked, setNeutralChecked] = useState(false);
  const [tag1, setTag1] = useState(false);
  const [tag2, setTag2] = useState(false);
  const [tag3, setTag3] = useState(false);
  const [tag1Active, setTag1Active] = useState(false);
  const [tag2Active, setTag2Active] = useState(false);
  const [tag3Active, setTag3Active] = useState(false);
  const [DS1, setDS1] = useState(true);
  const [DS2, setDS2] = useState(false);
  const [DS3, setDS3] = useState(false);
  const [selectedLaws, setSelectedLaws] = useState<Law[]>(["fed"]);
  const [DS4, setDS4] = useState(false);
  const [assistant1, setAssistant1] = useState(true);
  const [assistant2, setAssistant2] = useState(false);
  const [assistant3, setAssistant3] = useState(false);
  const [assistant4, setAssistant4] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [loginActive, setLoginActive] = useState(true);
  const [feedbackType, setFeedbackType] = useState("");
  const [neutralIcon, setNeutralIcon] = useState(neutralInactive);
  const [likeIcon, setLikeIcon] = useState(likeInactive);
  const [dislikeIcon, setDislikeIcon] = useState(dislikeInactive);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [messageLoading, setMessageLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(
    JSON.parse(localStorage.getItem("termsAccepted") || "false")
  );
  const [userToken, setUserToken] = useState(
    localStorage.getItem("userToken") ||
    sessionStorage.getItem("userToken") ||
    ""
  );
  const [uId, setUId] = useState(
    localStorage.getItem("userId") ||
    sessionStorage.getItem("userId") ||
    ""
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("userRefreshToken") ||
    sessionStorage.getItem("userRefreshToken") ||
    ""
  );

  const [stayLoggedIn, setStayLoggedIn] = useState(
    JSON.parse(localStorage.getItem("stayLoggedIn") ?? "false")
  );
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [userEmail, setUserEmail] = useState(
    localStorage.getItem("userEmail") ||
    sessionStorage.getItem("userEmail") ||
    ""
  );
  const [userPassword, setUserPassword] = useState(
    localStorage.getItem("userPassword") ||
    sessionStorage.getItem("userPassword") ||
    ""
  );

  const [convID, setConvId] = useState(sessionStorage.getItem("convId") || "");

  const [remainingChance, setRemainingChance] = useState(sessionStorage.getItem("remainingChances") || "");
  const [purchasedChances, setPurchasingChance] = useState(sessionStorage.getItem("purchasedChances") || "");

  const [history, setHistory] = useState(
    JSON.parse(sessionStorage.getItem("history") ?? "{}")
  );
  const [historyFiltered, setHistoryFiltered] = useState(
    JSON.parse(sessionStorage.getItem("historyFiltered") ?? "{}")
  );
  const [showLinksAndIcons, setShowLinksAndIcons] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [guestLogin, setGuestLogin] = useState(true);
  const [userLogin, setUserLogin] = useState(
    localStorage.getItem("userLogin") ||
    sessionStorage.getItem("userLogin") ||
    ""
  );
  const [subscriberLogin, setSubscriberLogin] = useState(
    localStorage.getItem("subscriberLogin") ||
    sessionStorage.getItem("subscriberLogin") ||
    ""
  );
  const [controller, setController] = useState<AbortController | null>(null);
  const [cancelTokens, setCancelTokens] = useState<CancelTokenSource[]>([]);
  const [guestChecked, setGuestChecked] = useState(false);
  const [signupChecked, setSignupChecked] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [feedbackMsg, setFeedbackMsg] = useState("");
  const [showGuestLimitError, setShowGuestLimitError] = useState(false);
  const [showUserLimitError, setUserLimitError] = useState(false);
  const [agbChecked, setAgbChecked] = useState(
    localStorage.getItem("agbChecked") ||
    sessionStorage.getItem("agbChecked") ||
    ""
  );
  const [disclaimerChecked, setDisclaimerChecked] = useState(
    localStorage.getItem("disclaimerChecked") ||
    sessionStorage.getItem("disclaimerChecked") ||
    ""
  );
  const [formValidation, setFormValidation] = useState(true);
  const [addImageUrl1, setAddImageUrl1] = useState<string>();
  const [addLink1, setAddLink1] = useState<string>();
  const [addImageUrl2, setAddImageUrl2] = useState<string>();
  const [addLink2, setAddLink2] = useState<string>();
  // const [showAdds, setShowAdds] = useState(false);
  // const [isAnimating, setIsAnimating] = useState(false);
  const [popupTimer, setPopupTimer] = useState(5);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [closing, setClosing] = useState(false);
  const [showErrorAgbs, setShowErrorAgbs] = useState(false);
  const [showErrorDisclaimer, setShowErrorDisclaimer] = useState(false);
  const agbRef = useRef<HTMLInputElement>(null);
  const disclaimerRef = useRef<HTMLInputElement>(null)
  const [paypalLoading, setPayPalLoading] = useState(false);
  const [showSettingModel, setShowSettingModel] = useState(false);
  const [inputSettingCode, setInputSettingCode] = useState(
    localStorage.getItem("settingCode") ||
    sessionStorage.getItem("settingCode") ||
    ""
  );
  const [settingCode, setSettingCode] = useState(
    localStorage.getItem("settingCode") ||
    sessionStorage.getItem("settingCode") ||
    ""
  );
  const [settingCodeVisible, setSettingCodeVisible] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPopupTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    if (popupTimer === 0) {
      setShowCloseButton(true);
      clearInterval(intervalId);
      setClosing(true)
      setTimeout(() => {
        setShowAddModal(false);
        setClosing(false)
      }, 2000); // Fading out takes 1 second
    }

    return () => clearInterval(intervalId);
  }, [popupTimer]);


  const handleClose = () => {
    setShowAddModal(false);
    setClosing(true);
    setTimeout(() => {
      setShowAddModal(false);
      setClosing(false)
    }, 2000); // Fading out takes 1 second
  };

  // useEffect(() => {
  //   if (!userToken && !guestLogin) {
  //     setShowModal(true);
  //   }
  // }, [userToken]);

  async function checkAndRefreshToken() {
    setUserToken(await refreshUserToken());
  }

  useEffect(() => {
    ReactGA.initialize("G-GJPE4KDJJH");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    let addImageUrl1 = '', addImageUrl2 = '', addUrl1 = '', addUrl2 = '';
    fetchAndActivate(remoteConfig)
      .then(() => {
        console.log('Fetched remote config', remoteConfig);
        addImageUrl1 = getValue(remoteConfig, 'addImageUrl1').asString();
        setAddImageUrl1(addImageUrl1);
        addUrl1 = getValue(remoteConfig, 'addLink1').asString();
        setAddLink1(addUrl1);
        addImageUrl2 = getValue(remoteConfig, 'addImageUrl2').asString();
        setAddImageUrl2(addImageUrl2);
        addUrl2 = getValue(remoteConfig, 'addLink2').asString();
        setAddLink2(addUrl2);
      })
      .catch((err: any) => {
        console.log("Failed to fetch remote config", err);
      });
    let intervalId: any;

    if (userToken) {
      checkAndRefreshToken(); // Execute immediately on initial render
      intervalId = setInterval(() => {
        checkAndRefreshToken();
      }, 59 * 60 * 1000); // Every 59 minutes
    }

    // if (userLogin === "true" || subscriberLogin === "true") {
    //   setGuestLogin(false)
    //   checkUserIsSubscribed()
    // }
    return () => clearInterval(intervalId);
  }, []);

  const handleResetClick = () => {
    setNeutralChecked(false);
    setPositiveChecked(false);
    setNegativeChecked(false);
    setTag1(false);
    setTag2(false);
    setTag3(false);
  };

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  useEffect(() => {
    // Add event listener to update screen width on resize
    window.addEventListener("resize", updateScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (userToken) {
      console.log("token b4 getting ids:", userToken);
      getConversationIDs(userToken);
    }
  }, [userToken, messageToggle]);

  async function getConversationIDs(userToken: string) {
    console.log("get ids ran");
    setHistoryLoading(true);
    try {
      // const validity = await checkUserValidity();
      let token = userToken;
      // if (validity !== true) {
      //   token = await refreshUserToken();
      // }
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "get_convers_ids",
        {
          token: token,
        },
        {
          cancelToken: source.token,
        }
      );
      console.log(response);
      const { convers_ids } = response.data;
      setHistory(convers_ids);
      sessionStorage.setItem("history", JSON.stringify(convers_ids));
      const filteredHistory = filterHistory(convers_ids);
      setHistoryFiltered(filteredHistory);
      sessionStorage.setItem(
        "historyFiltered",
        JSON.stringify(filteredHistory)
      );
    } catch (error: any) {
      // toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      setHistoryLoading(false);
    }
  }

  async function submitFeedback() {
    console.log("submit feedback ran");

    setFeedbackLoading(true);
    try {
      // const validity = await checkUserValidity();
      let token = userToken;
      // if (validity !== true) {
      //   token = await refreshUserToken();
      // }
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const feedbackData = {
        token: guestLogin ? "" : token,
        feedback: feedbackMsg,
        conversation_id: convID,
        message_id: messageID,
        timestamp: new Date().toISOString(),
        status: feedbackType,
      };
      console.log("feedbackData:", feedbackData);

      const response = await axios.post(
        BASE_URL + "submit_feedback",
        feedbackData,
        {
          cancelToken: source.token,
        }
      );
      console.log(response);
      const { feedback_id } = response.data;
      console.log("feedback_id:", feedback_id);

      addFeedbackToMessage(feedback_id, feedbackData);
      toast.success("Feedback submitted");
      setShowFeedbackModal(false);
    } catch (error: any) {
      toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      setFeedbackLoading(false);
      setFeedbackMsg("");
    }
  }

  function addFeedbackToMessage(feedbackID: string, feedbackData: any) {
    const updatedMessages = { ...messages };

    updatedMessages[messageID].feedback = {
      feedback: feedbackData.feedback,
      timestamp: feedbackData.timestamp,
      status: feedbackData.status,
    };

    setMessages(updatedMessages);
    sessionStorage.setItem("messages", JSON.stringify(updatedMessages));
    updateMessagesList(feedbackData.conversation_id, updatedMessages);
  }

  async function getConvData(id: string) {
    if (convID == id) return;
    cancelAllRequests();
    console.log("get conv data ran");
    setChatLoading(true);
    // setProgress(0);
    setConvId(id);
    sessionStorage.setItem("convId", id);

    try {
      let token = userToken;

      const response = await axios.post(BASE_URL + "get_convers_data", {
        token: token,
        convers_id: id,
      });
      console.log(response);
      const { convers_data } = response.data;
      setMessages(convers_data);
      sessionStorage.setItem("messages", JSON.stringify(convers_data));
    } catch (error: any) {
      toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      // clearInterval(timer);
      setChatLoading(false);
    }
  }

  function getConvDataForGuest(id: string) {
    if (convID == id) return;
    cancelAllRequests();
    setConvId(id);
    sessionStorage.setItem("convId", id);
    setMessages(messagesList[id]);
    sessionStorage.setItem("messages", JSON.stringify(messagesList[id]));
    console.log("messagesList[id]:", messagesList[id]);
  }

  async function newChatByBtn() {
    if (isEmpty(messages)) return;
    setMessages({});
    setConvId("");
    sessionStorage.removeItem("messages");
    sessionStorage.removeItem("convId");
  }

  async function newChat(token: string) {
    console.log("new chat ran");
    try {
      const createConvData: any = {
        question: chatInput,
      };
      //if (!guestLogin) {
      createConvData.token = token;
      createConvData.code = settingCode;
      //}
      console.log("createConvData", createConvData);
      console.log("guest login val:", guestLogin);
      console.log("guest login has val?:", guestLogin ? true : false);

      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + `${(guestLogin || userLogin) ? "create_guest_convers" : "create_convers"}`,
        createConvData,
        {
          cancelToken: source.token,
        }
      );
      const { convers_id } = response.data;
      console.log("Conversation ID: ", convers_id);
      setConvId(convers_id);
      sessionStorage.setItem("convId", convers_id);
      return convers_id;
    } catch (error: any) {
      if (error.response?.data?.message?.includes("Daily limit")) {
        const tempUserLogin = userLogin
        if (tempUserLogin) {
          setUserLimitError(true)
          setChatInput("");
        } else {
          logoutAction();
        }
        setShowAddModal(false)
        setShowModal(true);
        setShowGuestLimitError(true);

        return;
      }
      toast.error(getErrorMessage(error));
      console.error(error);
      setMessageLoading(false);
      setMessageCompleted(true);
    }
  }

  const getUserId = async (idToken: string) => {
    try {
      console.log("get uid");
      const getUidData: any = {
        token: idToken
      };

      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "/get_UID",
        getUidData,
        {
          cancelToken: source.token,
        }
      );
      const { UID } = response.data;
      if (UID) {
        setUId(UID)
        if (stayLoggedIn) {
          localStorage.setItem("userId", UID);
        } else {
          sessionStorage.setItem("userId", UID);
        }
      }
    } catch (error: any) {
      console.error("Error getting user id", error);
    }
  }

  async function checkGuestLimit(token?: string) {
    try {
      console.log("checking limit");
      const createGuestLimitData: any = {
        token: token ?? ""
      };
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "remaining_convers_chances",
        createGuestLimitData,
        // BASE_URL + `remaining_convers_chances`,
        {
          cancelToken: source.token,
        }
      );
      const { remaining_chances, purchased_chances } = response.data;
      console.log("Remaining Chance, Purchased Chances ", remaining_chances, purchased_chances);
      setRemainingChance(remaining_chances);
      setPurchasingChance(purchased_chances)
      sessionStorage.setItem("remainingChances", remaining_chances);
      sessionStorage.setItem("purchasedChances", purchased_chances);
      return { remaining_chances, purchased_chances };
    } catch (error: any) {
      console.error("Error fetching remaining chances from the api", error);
    }
  }


  const handlePaypalClick = () => {
    const windowRef = window.open("", "_blank");
    generatePaypalLink(windowRef);
  };

  const generatePaypalLink = async (windowRef: Window | null) => {
    try {
      setPayPalLoading(true);
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post<{ paypal_link: string }>(
        BASE_URL + "generate_paypal_link",
        {},
        {
          cancelToken: source.token,
        }
      );
      const { paypal_link } = response.data;
      console.log("Paypal link ", paypal_link);
      if (paypal_link) {
        if (windowRef) {
          windowRef.location.href = paypal_link;
        }
        setPayPalLoading(false);
        setShowModal(false);
      } else {
        setPayPalLoading(false);
      }
    } catch (error: any) {
      setPayPalLoading(false);
      console.error("Error getting paypal link from the API", error);
    }
  };
  async function checkUserIsSubscribed() {
    try {
      console.log("user subscription checking");
      const createUserSubscriptionChecking: any = {
        token: userToken
      };
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "check_subscription_status",
        createUserSubscriptionChecking,
        // BASE_URL + `remaining_convers_chances`,
        {
          cancelToken: source.token,
        }
      );
      console.log("checking user subscription", response.data)
      const { subscribed } = response.data;
      console.log("Is Subscribed ", subscribed);
      setSubscriberLogin(subscribed.toString())
      setUserLogin((!subscribed).toString())
      localStorage.setItem("subscriberLogin", subscribed.toString());
      localStorage.setItem("userLogin", (!subscribed).toString());
      return subscribed;
    } catch (error: any) {
      console.error("Error fetching subscription status", error);
    }
  }


  async function unSubscribeUser() {
    try {
      const unSubscibeBody: any = {
        token: userToken
      };
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "cancel_subscription",
        unSubscibeBody,
        {
          cancelToken: source.token,
        }
      );
      console.log("unsubscribing user", response)
      if (response.status === 200) {
        logoutAction();
        setShowModal(true);
      }
    } catch (error: any) {
      console.error("Error fetching subscription status", error);
    }
  }


  async function checkUserValidity() {
    try {
      console.log("checking for token validity");
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "check_user_validity",
        {
          token: userToken,
        },
        {
          cancelToken: source.token,
        }
      );
      console.log(response);
      return response.data.message;
    } catch (error) {
      // toast.error(getErrorMessage(error));
      console.error(error);
    }
  }

  async function refreshUserToken() {
    try {
      console.log("refreshing token");
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + "refresh_token",
        {
          token: refreshToken,
        },
        {
          cancelToken: source.token,
        }
      );
      console.log(response);
      const { id_token } = response.data;
      setUserToken(id_token);
      if (stayLoggedIn) {
        localStorage.setItem("userToken", id_token);
      } else {
        sessionStorage.setItem("userToken", id_token);
      }
      getUserId(id_token);
      return id_token;
    } catch (error) {
      // toast.error(getErrorMessage(error));
      console.error(error);
    }
  }

  async function getLastMessageURL(conversId: string) {
    try {
      console.log("getting last msg url");
      const source = axios.CancelToken.source();
      setCancelTokens((tokens) => [...tokens, source]);

      const response = await axios.post(
        BASE_URL + `${guestLogin ? "get_guest_urls" : "get_urls"}`,
        {
          token: userToken,
          convers_id: conversId,
        },
        {
          cancelToken: source.token,
        }
      );
      return response.data;
    } catch (error) {
      // toast.error(getErrorMessage(error));
      console.error(error);
    }
  }

  const sendMessage = async () => {
    if (!chatInput) return;
    if (disclaimerChecked === "false" || !disclaimerChecked) {
      setDisclaimerChecked("false")
      setShowErrorDisclaimer(true)
      if (!disclaimerChecked && disclaimerRef.current && disclaimerRef.current.parentElement) {
        disclaimerRef.current.parentElement.classList.add('focused');
      }
      return;
    }
    ReactGA.send({ hitType: "Send button click", page: window.location.pathname });
    window.scrollTo(0, 0);
    setMessageLoading(true); // Set loading to true when button is clicked
    setProgress(0);
    console.log("checking subscirber login ", subscriberLogin)

    let responseMsg = "";
    let msgObj: Message = {
      user: chatInput,
      assistant: responseMsg,
      timestamp: new Date().toISOString(),
    };
    console.log("old messages", messages)
    const newMessages: Messages = { "0": msgObj };
    let index = 1;
    for (const key in messages) {
      newMessages[index.toString()] = messages[key];
      index++;
    }
    console.log("new Messages", newMessages)
    setMessages(newMessages);

    if (subscriberLogin != "true") {
      checkGuestLimit(userToken).then((data) => {
        if (data) {
          const { remaining_chances, purchased_chances } = data;
          console.log("Remaining Chances:", remaining_chances);
          console.log("Purchased Chances:", purchased_chances);
          console.log("userLogin:", userLogin);
          if (remaining_chances > 0 || remaining_chances === undefined || purchased_chances > 0 || settingCode === "Nei2K32wLMefX5L8oTy7V") {
            // setTimeout(async () => {
            //   setShowCloseButton(false)
            //   setPopupTimer(5)
            //   setShowAddModal(true)
            //   console.log("new message1", newMessages)
            showResponseOfMessage(responseMsg, msgObj, newMessages)
            // }, 2500);
          } else {
            delete newMessages["0"];
            setMessages(newMessages);
            if (userLogin === "true") {

              setShowSubscribeModal(true)
            } else {
              setLoginEmail("")
              setLoginPassword("")
              setShowGuestLimitError(true);
              setShowModal(true)
            }
            setMessageLoading(false);
            setMessageCompleted(true);
            return
          }
        }
      }).catch((error) => {
        console.error("Error:", error);
      });
    } else {
      showResponseOfMessage(responseMsg, msgObj, newMessages)
    }
  };

  async function showResponseOfMessage(responseMsg: string, msgObj: any, messages: any) {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const diff = 0.001 * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 0);
    try {
      let token = userToken;
      let msgList = messages;
      let conversId = convID;
      console.log("new message", convID, messages)
      if (!convID || isEmpty(messages) || guestLogin) {
        if (guestLogin) msgList = {};
        conversId = await newChat(token);
      }

      if (!conversId) return;

      const chatData: any = {
        content: chatInput,
        lang: "English",
        convers_id: conversId,
      };
      if (!guestLogin) {
        chatData.token = token;
      } else {
        chatData.values = selectedLaws
      }
      console.log("chat data", chatData)
      chatData.code = settingCode
      const charHeaders = {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };
      console.log("chatData:", chatData);


      // msgList = [...messages, msgObj];

      if (chatInput) setChatInput("");

      const abortController = new AbortController();
      setController(abortController);

      const response = await fetch(
        BASE_URL + `${guestLogin ? "guest_chat" : "chat"}`,
        {
          method: "post",
          signal: abortController.signal,
          headers: charHeaders,
          body: JSON.stringify(chatData),
        }
      );
      if (!response.ok || !response.body) {
        if (guestLogin && response.status === 400) {
          toast.error(
            "A guest can ask only 1 question per conversation and a total of 3 questions."
          );
        }
        if (response.status === 429) {
          toast.error("User limited completed")
        }
        console.log("fetch api error");
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const decodedChunk = decoder.decode(value, { stream: true });
        responseMsg += decodedChunk; // update state with new chunk
        msgObj = {
          ...msgObj,
          user: chatInput,
          assistant: responseMsg,
        };
        msgList["0"] = msgObj;
        setMessages(msgList);
        setMessageCompleted(false);
      }
      const urlData = await getLastMessageURL(conversId);
      msgObj.titles = urlData?.titles;
      msgObj.urls = urlData?.urls;
      sessionStorage.setItem("messages", JSON.stringify(msgList));
      console.log("msgList:", msgList);
      setMessageToggle(!messageToggle);
      // history for guest
      updateHistory(conversId, chatInput);
      updateMessagesList(conversId, msgList);
    } catch (error: any) {
      removeUncompletedMessage();
      toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      clearInterval(timer);
      setMessageLoading(false);
      setMessageCompleted(true);
    }
  }

  function removeUncompletedMessage() {
    const updatedMsgs = Object.fromEntries(
      Object.entries(messages).filter(
        ([key, value]: [string, Message]) => value.assistant ?? false
      )
    );
    setMessages(updatedMsgs);
  }

  function updateHistory(conversId: string, chatInput: string) {
    const updatedHistory = { ...history };
    if (!updatedHistory[conversId]) {
      updatedHistory[conversId] = {
        user: chatInput,
        timestamp: new Date(),
      };
      setHistory(updatedHistory);
      sessionStorage.setItem("history", JSON.stringify(updatedHistory));
      const filteredHistory = filterHistory(updatedHistory);
      setHistoryFiltered(filteredHistory);
      sessionStorage.setItem(
        "historyFiltered",
        JSON.stringify(filteredHistory)
      );
    }
  }

  function updateMessagesList(conversId: string, msgList: Messages) {
    const updateMessagesList = { ...messagesList, [conversId]: msgList };
    setMessagesList(updateMessagesList);
    sessionStorage.setItem("messagesList", JSON.stringify(updateMessagesList));
  }

  const handleLoginFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  const loginAction = async () => {
    try {
      setLoginLoading(true);
      const postData = {
        email: loginEmail,
        password: loginPassword,
      };
      const response = await axios.post(BASE_URL + LOGIN_ENDPOINT, postData);
      console.log("Response:", response.data);
      const { token, refresh_token, message, role, link } = response.data;

      setUserToken(token);
      setRefreshToken(refresh_token);
      setUserEmail(loginEmail);
      setUserPassword(loginPassword);
      setSubscriberLogin(role.toString())
      setUserLogin((!role).toString())
      if (stayLoggedIn) {
        localStorage.setItem("userToken", token);
        localStorage.setItem("userRefreshToken", refresh_token);
        localStorage.setItem("userEmail", loginEmail);
        localStorage.setItem("userPassword", loginPassword);
        localStorage.setItem("subscriberLogin", role.toString());
        localStorage.setItem("userLogin", (!role).toString());
        localStorage.setItem("userId", uId);
      } else {
        sessionStorage.setItem("userToken", token);
        sessionStorage.setItem("userRefreshToken", refresh_token);
        sessionStorage.setItem("userEmail", loginEmail);
        sessionStorage.setItem("userPassword", loginPassword);
        sessionStorage.setItem("subscriberLogin", role.toString());
        sessionStorage.setItem("userLogin", (!role).toString());
        sessionStorage.setItem("userId", uId);
      }
      getUserId(token)

      setGuestLogin(false);
      sessionStorage.removeItem("guestLogin");
      // await getConversationIDs(token);
      toast.success(message);
      setMessages({});
      setShowModal(false);
      setShowGuestLimitError(false);
      setUserLimitError(false)
    } catch (error: any) {
      toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      setLoginLoading(false);
    }
  };

  const signupAction = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoginLoading(true);
      if (signupPassword != repeatPassword) {
        toast.error("Password doesn't match");
        return;
      }
      const postData = {
        email: signupEmail,
        password: signupPassword,
      };
      const response = await axios.post(BASE_URL + SIGNUP_ENDPOINT, postData);
      console.log("Response:", response.data);
      const { token, refreshToken, message, user_id, stripe } = response.data;
      setUId(user_id)
      setUserToken(token);
      setRefreshToken(refreshToken);
      setUserEmail(loginEmail);
      setUserPassword(loginPassword);
      setSubscriberLogin("false")
      setUserLogin("true")
      setGuestLogin(false);
      sessionStorage.removeItem("guestLogin");
      localStorage.setItem("subscriberLogin", "false");
      localStorage.setItem("userLogin", "true");

      localStorage.setItem("userToken", token);
      localStorage.setItem("userRefreshToken", refreshToken);
      localStorage.setItem("userEmail", signupEmail);
      localStorage.setItem("userPassword", signupPassword);
      getUserId(token)
      toast.success(message);
      setMessages({});
      setShowModal(false);
      setShowGuestLimitError(false)
      setUserLimitError(true)
      if (stripe) {
        window.location.href = stripe;
      }
    } catch (error: any) {
      toast.error(getErrorMessage(error));
      console.error(error);
    } finally {
      setLoginLoading(false);
    }
  };

  const guestAction = () => {
    setFormValidation(false)
    console.log("loggin")
    if (!agbChecked || !disclaimerChecked) {
      return;
    }
    console.log("loggin1")
    setGuestLogin(true);
    setSubscriberLogin("false")
    setUserLogin("false")
    sessionStorage.setItem("guestLogin", "true");
    sessionStorage.setItem("subscriberLogin", "false");
    sessionStorage.setItem("userLogin", "false");
    setMessages({});
    setShowModal(false);
    setShowGuestLimitError(false);
    setUserLimitError(false)
  };

  const logoutAction = () => {
    localStorage.clear();
    sessionStorage.clear();
    setChatInput("");
    setUserToken("");
    setRefreshToken("");
    setMessages({});
    setMessagesList({});
    setShowModal(true);
    setHistory({});
    setHistoryFiltered({});
    setConvId("");
    setGuestLogin(true);
    setUserLogin("");
    setSubscriberLogin("");
    cancelAllRequests();
    setLoginEmail("");
    setLoginPassword("");
    setSignupEmail("");
    setSignupPassword("");
    setRepeatPassword("");
    setUserEmail("");
    setUserPassword("");
    setTermsAccepted(false);
    setShowGuestLimitError(false);
    setUserLimitError(false)
    setUId("")
  };


  const boldStyle: React.CSSProperties = {
    fontWeight: 'bold'
  };

  const forgetPassword = async () => {
    try {
      const response = await axios.post(
        BASE_URL + `forgot_password?email=${loginEmail}`
      );
      toast.success(response.data.message);
    } catch (error) {
      toast.error(getErrorMessage(error));
      console.error(error);
    }
  };

  const cancelAllRequests = () => {
    if (!isEmpty(cancelTokens)) {
      cancelTokens.forEach((token) => {
        token.cancel("Request canceled by user");
      });
      setCancelTokens([]);
    }

    if (controller) {
      controller.abort();
      setController(null);
    }
  };

  const searchAction = () => {
    if (collapsed) {
      setCollapsed(!collapsed);
    }
  };

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
    const parsedHistory: FilteredHistory = JSON.parse(
      sessionStorage.getItem("historyFiltered") ?? "{}"
    );
    let filteredHistory: FilteredHistory = {};

    if (e.target.value) {
      filteredHistory = {};

      // Iterate over each entry in parsedHistory
      Object.entries(parsedHistory).forEach(([key, value]) => {
        if (value) {
          // Filter the HistoryObj array based on the condition
          const filteredArray = value.filter((historyObj: HistoryObj) => {
            return historyObj.user
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          });

          // Add filtered array to filteredHistory if it's not empty
          if (filteredArray.length > 0) {
            filteredHistory[key] = filteredArray;
          }
        }
      });
    } else {
      // If search value is empty, set filteredHistory to parsedHistory
      filteredHistory = parsedHistory;
    }

    setHistoryFiltered(filteredHistory);
  }

  const toggleSidePanel = () => {
    if (screenWidth > 768) setCollapsed(!collapsed);
    setMobileCollapsed(!mobileCollapsed);
  };

  const toggleMobileSidePanel = () => {
    if (screenWidth <= 768) setMobileCollapsed(!mobileCollapsed);
  };

  function handleFeedbackModal(feedback: string) {
    setFeedbackType(feedback);
    setShowFeedbackModal(true);
  }

  function getFeedbackIcon() {
    if (feedbackType === "neutral") return neutralActive;
    if (feedbackType === "positive") return likeActive;
    if (feedbackType === "negative") return dislikeActive;
  }

  const handleAgbCheckChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const isChecked = e.target.checked;
    sessionStorage.setItem("agbChecked", isChecked.toString());
    setAgbChecked(isChecked ? "true" : "false");
  };

  const handleDisclaimerCheckChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const isChecked = e.target.checked;
    sessionStorage.setItem("disclaimerChecked", isChecked.toString());
    setDisclaimerChecked(isChecked ? "true" : "false");
  };

  const [suggestions, setSuggestions] = useState<string[]>([
    'Mein Flug hatte Verspätung. Bekomme ich mein Geld zurück?',
    'Ich wurde gekündigt. Bekomme ich eine Abfindung?',
    'Ich bin in der 30er Zone zu schnell gefahren. Was passiert nun?',
  ]);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);


  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setChatInput(value);
    // if(value){
    //   setFilteredSuggestions([])
    // }else { setFilteredSuggestions(suggestions);}
  };


  const handleInputClick = (e: MouseEvent<HTMLInputElement>): void => {
    e.stopPropagation(); // Prevent click from propagating to document
    setFilteredSuggestions(suggestions);
  };



  // Function to handle suggestion click
  const handleSuggestionClick = (suggestion: string): void => {
    setChatInput(suggestion);
    setFilteredSuggestions([]);
  };


  // Function to handle enter key
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleClickOutside = (): void => {
    setFilteredSuggestions([]);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const updateSelectedLaws = (law: Law, shouldAdd: boolean) => {
    setSelectedLaws(prevLaws => {
      if (shouldAdd) {
        // Add the law if it's not already included
        return prevLaws.includes(law) ? prevLaws : [...prevLaws, law];
      } else {
        // Remove the law if it's included
        return prevLaws.filter(existingLaw => existingLaw !== law);
      }
    });
  };


  return (
    <div>
      <div className="chatMainFrame">
        <div
          className={`overlay ${!mobileCollapsed ? "active" : ""}`}
          onClick={toggleSidePanel}
        />
        {((!collapsed && screenWidth > 768) || (!mobileCollapsed && screenWidth <= 768)) &&
          <div className={`sidepanel ${!mobileCollapsed ? "active" : ""}`}>
            <Tooltip title={""}>
              <div className="side-toggle" onClick={toggleSidePanel}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="26"
                  viewBox="0 0 15 26"
                  fill="none"
                >
                  <rect width="15" height="26" rx="4" fill="#F3F6F5" />
                  <line x1="5.5" y1="7" x2="5.5" y2="19" stroke="#0D3646" />
                  <line x1="9.5" y1="7" x2="9.5" y2="19" stroke="#0D3646" />
                </svg>
              </div>
            </Tooltip>
            <div className="search-section">
              <div
                className={`d-flex align-items-center searchbox ${(!collapsed || (!mobileCollapsed && screenWidth <= 768)) &&
                  ""
                  }`}
                onClick={searchAction}
              >
                {(!collapsed || (!mobileCollapsed && screenWidth <= 768)) && (
                  <input
                    type="text"
                    placeholder="Search"
                    className="searchinput me-1"
                    onChange={handleSearchChange}
                  />
                )}

                <svg
                  className="clickable"
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                >
                  <path
                    d="M10.3746 19.2873C5.57211 19.2873 1.66211 15.3773 1.66211 10.5748C1.66211 5.77231 5.57211 1.8623 10.3746 1.8623C15.1771 1.8623 19.0871 5.77231 19.0871 10.5748C19.0871 15.3773 15.1771 19.2873 10.3746 19.2873ZM10.3746 3.13731C6.26912 3.13731 2.93711 6.47781 2.93711 10.5748C2.93711 14.6718 6.26912 18.0123 10.3746 18.0123C14.4801 18.0123 17.8121 14.6718 17.8121 10.5748C17.8121 6.47781 14.4801 3.13731 10.3746 3.13731Z"
                    fill="white"
                  />
                  <path
                    d="M19.3002 20.1374C19.1387 20.1374 18.9772 20.0779 18.8497 19.9504L17.1497 18.2504C16.9032 18.0039 16.9032 17.5959 17.1497 17.3494C17.3962 17.1029 17.8042 17.1029 18.0507 17.3494L19.7507 19.0494C19.9972 19.2959 19.9972 19.7039 19.7507 19.9504C19.6232 20.0779 19.4617 20.1374 19.3002 20.1374Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            {(!collapsed || (!mobileCollapsed && screenWidth <= 768)) &&
              !isEmpty(historyFiltered) && (
                <div className="history">
                  {Object.entries(historyFiltered ?? {}).map(
                    ([key, value]: [string, any]) => (
                      <div className="mb-4">
                        <div className="historyHeading">{key}</div>
                        {/* <Tooltip title={value} placement="right"> */}
                        {value.map((item: any) => (
                          <div
                            className={`historyText ${item.key == convID && "active"
                              }`}
                            key={item.key}
                            onClick={() => {
                              toggleMobileSidePanel();
                              guestLogin
                                ? getConvDataForGuest(item.key)
                                : getConvData(item.key);
                            }}
                          >
                            {item.user}
                          </div>
                        ))}

                        {/* </Tooltip> */}
                      </div>
                    )
                  )}
                </div>
              )}
          </div>
        }
        <div className="chatpanel">
          {(collapsed || (mobileCollapsed && screenWidth <= 768)) && (
            <Tooltip title={""}>
              <div className={`side-toggle-2`} onClick={toggleSidePanel}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="26"
                  viewBox="0 0 15 26"
                  fill="none"
                >
                  <rect width="15" height="26" rx="4" fill="none" />  {/* Removed fill color for transparency */}
                  <line x1="5.5" y1="7" x2="5.5" y2="19" stroke="#0D3646" />
                  <line x1="9.5" y1="7" x2="9.5" y2="19" stroke="#0D3646" />
                </svg>
              </div>
            </Tooltip>
          )}
          <div className="top-section">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="d-flex chatBox">
                <div className="d-flex">


                  <div className="dropdown">
                    <button
                      className="ds-dropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_3_657)">
                          <path
                            d="M3 6.47214C3 6.16165 3.07229 5.85542 3.21115 5.57771L4 4H9L10 6H20C20.5523 6 21 6.44772 21 7V9V19C21 19.5523 20.5523 20 20 20H4C3.44772 20 3 19.5523 3 19V9V6.47214Z"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4 20H20C20.5523 20 21 19.5523 21 19V11C21 9.89543 20.1046 9 19 9H5C3.89543 9 3 9.89543 3 11V19C3 19.5523 3.44772 20 4 20Z"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3_657">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>

                    <ul
                      className="dropdown-menu"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          <label
                            htmlFor="ds1"
                            className="d-flex align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="ds1"
                              checked={DS1}
                              // onChange={() => {
                              //   setDS1(prevDS1 => {
                              //     const newDS1 = !prevDS1;
                              //     updateSelectedLaws("fed", newDS1);
                              //     return newDS1;
                              //   });
                              // }}
                            />
                            <span>Bundesgesetze & -verordnungen</span>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item " href="#">
                          <label
                            htmlFor="ds2"
                            className="d-flex check-group"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input checkbox-bg me-2"
                              id="ds2"
                              checked={DS2}
                              onChange={() => {
                                setDS2(prevDS2 => {
                                  const newDS2 = !prevDS2;
                                  updateSelectedLaws("bayGe", newDS2);
                                  return newDS2;
                                });
                              }}
                            />
                            <span>Bayern - Gesetze</span>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item " href="#">
                          <label
                            htmlFor="ds3"
                            className="d-flex check-group"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="ds3"
                              checked={DS3}
                              onChange={() => {
                                setDS3(prevDS3 => {
                                  const newDS3 = !prevDS3;
                                  updateSelectedLaws("bayVe", newDS3);
                                  return newDS3;
                                });
                              }}
                            />
                            <span>Bayern - Verordnungen</span>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item disabled" href="#">
                          <label
                            htmlFor="ds4"
                            className="d-flex check-group"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="ds4"
                              checked={DS4}
                              onChange={() => setDS4(!DS4)}
                            />
                            <span>weitere Datensätze (demnächst)</span>
                          </label>
                        </a>
                      </li>
                      <li className="text-center mt-2">
                        <button className="btn upload-btn disabled">Upload</button>
                      </li>
                    </ul>
                  </div>
                </div>

                

                <input
                  className="chatInput"
                  id="chat"
                  placeholder="Frage hier eingeben"
                  value={chatInput}
                 // onClick={handleInputClick}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
                {filteredSuggestions.length > 0 && (
                  <ul className="suggestionList">
                    {filteredSuggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestionItem"
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
                <div className="d-flex">

                  <button
                    className={`sendBtn`}
                    onClick={sendMessage}
                    disabled={messageLoading ? true : false}
                    style={{
                      cursor: `${messageLoading ? "not-allowed" : "pointer"}`,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M13 1L1 3.6087L6.73913 7.26084M13 1L10.3913 13L6.73913 7.26084M13 1L6.73913 7.26084"
                        stroke="white"
                        strokeWidth="1.4757"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="ms-2">Senden</span>
                  </button>
                </div>

                <div className="d-flex">
                  <div className="dropdown">
                    <button
                      className="ms-dropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_3_665)">
                          <path
                            d="M13.4043 16H23.7505"
                            stroke="white"
                            strokeWidth="1.29167"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.4126 16H9.06385"
                            stroke="white"
                            strokeWidth="1.29167"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.4043 20.5332H23.7505"
                            stroke="white"
                            strokeWidth="1.29167"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.4126 20.5332H9.06385"
                            stroke="white"
                            strokeWidth="1.29167"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.4043 11.4668H23.7505"
                            stroke="white"
                            strokeWidth="1.29167"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.4126 11.4668H9.06385"
                            stroke="white"
                            strokeWidth="1.29167"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3_665">
                            <rect
                              width="31"
                              height="31"
                              fill="white"
                              transform="translate(0.5 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>

                    <ul
                      className="dropdown-menu"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          <label
                            htmlFor="assistant1"
                            className="d-flex align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="assistant1"
                              checked={assistant1}
                              onChange={() => setAssistant1(!assistant1)}
                            />
                            <span>Assistent 1</span>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item disabled" href="#">
                          <label
                            htmlFor="assistant2"
                            className="d-flex check-group"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input checkbox-bg me-2"
                              id="assistant2"
                              checked={assistant2}
                              onChange={() => setAssistant2(!assistant2)}
                            />
                            <span>Assistent 2</span>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item disabled" href="#">
                          <label
                            htmlFor="assistant3"
                            className="d-flex check-group"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="assistant3"
                              checked={assistant3}
                              onChange={() => setAssistant3(!assistant3)}
                            />
                            <span>Assistent 3</span>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item disabled" href="#">
                          <label
                            htmlFor="assistant4"
                            className="d-flex check-group"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="assistant4"
                              checked={assistant4}
                              onChange={() => setAssistant4(!assistant4)}
                            />
                            <span>Assistant 4</span>
                          </label>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {isEmpty(messages) ? (
            <div className="landing-section">
              <div className="questionsContainer">
                <button
                  className="questionButton"
                  onClick={() => {
                    setChatInput("Mein Flug hatte Verspätung. Bekomme ich mein Geld zurück?");
                  }}
                >
                  Mein Flug hatte Verspätung. Bekomme ich mein Geld zurück?
                </button>
                <button
                  className="questionButton"
                  onClick={() => {
                    setChatInput("Ich wurde gekündigt. Bekomme ich eine Abfindung?");
                  }}
                >
                  Ich wurde gekündigt. Bekomme ich eine Abfindung?
                </button>
                <button
                  className="questionButton"
                  onClick={() => {
                    setChatInput("Ich bin in der 30er Zone zu schnell gefahren. Was passiert nun?");
                  }}
                >
                  Ich bin in der 30er Zone zu schnell gefahren. Was passiert nun?
                </button>
              </div>
              {/* <div className="playContainer">
                <PlayArrow className="playIcon" />
                <a
                  href="https://www.youtube.com/watch?v=YOUR_VIDEO_ID"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="playButton"
                >
                  see how it works
                </a>
              </div> */}
              <div className="content-wrapper">
                <div className="line-separator">
                  <hr className="line" />
                  <span className="or-text">Disclaimer/ Haftungsausschluss</span>
                  <hr className="line" />
                </div>
                <div className="landing-para form-group form-check">
                  <input
                    type="checkbox"
                    className={`form-check-input clickable ${showErrorDisclaimer && (disclaimerChecked === "false") ? 'error-disclaimerChecked' : ''}`}
                    id="accept-terms-2"
                    checked={disclaimerChecked === "true"}
                    onChange={handleDisclaimerCheckChange}
                    ref={disclaimerRef}

                  />
                  <label htmlFor="accept-terms-2" className={`clickable ${(showErrorDisclaimer && (disclaimerChecked === "false")) ? 'error-label-disclaimerChecked' : ''}`}>
                    Mir ist bewusst: WhatsLegal ist nur eine maschinengenerierte Zusammenfassung gesetzlicher Bestimmungen und liefert möglicherweise keine vollständige und angemessene Antwort, auch weil jede rechtliche Einordnung davon abhängt, ob alle relevanten Tatsachen offengelegt werden. Die einzelnen Ergebnisse können sich wesentlich ändern, wenn weitere Tatsachen mitgeteilt werden, die vorher nicht eingegeben wurden.  Es ist keine juristische Beratungsleistung durch eine/n Jurist/in und ersetzt diese nicht. Es kommt kein Mandatsverhältnis zustande. Wenden Sie sich in jedem Fall an eine/n fachkundige/n Jurist/-in. Geben Sie keine personenbezogenen Daten ein.
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div className="message-section">
              {Object.entries(
                Object.fromEntries(
                  Object.entries(messages).filter(
                    ([key, value]) => "assistant" in value
                  )
                ))
                .map(([key, value]: [string, Message], index, array) => (
                  <div key={key}>
                    <div className="user-div">
                      <div className="user">
                        <div
                          className="messageText"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {value.user}
                        </div>
                      </div>
                    </div>
                    <div className="assistant">
                      <div>
                        <span className="me-3 fw-bold d-flex align-items-center" >
                          <WLIcon />
                          <span className="ms-1">WhatsLegal</span>
                          <span className="time ms-3">
                            {getFormattedTime(value.timestamp)}
                          </span>
                        </span>
                      </div>
                      <div
                        className="messageText"
                      //   style={{ whiteSpace: "pre-wrap" }}
                      >
                        {value.assistant ? (
                          <ReactMarkdown>{value.assistant}</ReactMarkdown>
                        ) : (
                          <div
                            className="spinner-grow"
                            role="status"
                            style={{ color: "#0d3646" }}
                          />
                        )}
                      </div>
                      {!isEmpty(value.titles) && (
                        <div>
                          <div className="chat-links mt-3">
                            <div className="fw-bold mb-1">Gesetz(e): </div>
                            {value.titles?.map((_, index) => (
                              <div
                                className="d-flex clickable"
                                style={{ gap: "10px" }}
                                key={index}
                              >
                                <div className="lawLinks">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                  >
                                    <path
                                      d="M6.22794 4.77206L9.625 1.375M9.625 1.375V4.28676M9.625 1.375H6.71324M5.01471 1.375H2.45833C1.86002 1.375 1.375 1.86002 1.375 2.45833V8.54167C1.375 9.13997 1.86002 9.625 2.45833 9.625H8.54167C9.13997 9.625 9.625 9.13997 9.625 8.54167V5.98529"
                                      stroke="black"
                                      strokeWidth="0.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <a
                                    href={value.urls?.[index] ?? ""}
                                    target="_blank"
                                    className="link-text"
                                  >
                                    {value.titles?.[index] ?? ""}
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="disclaimer">
                            WhatsLegal ist nur eine maschinengenerierte Zusammenfassung gesetzlicher Bestimmungen und stellt keine juristische Beratungsleistung durch einen Mensch dar und ersetzt diese auch nicht. Auch überzeugend erscheinende Antworten sollten ggf. durch eine fachkundige Person (z.B. Rechtsanwalt/-in) überprüft werden.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        id="loginModal"
        centered
        dialogClassName="signupModal"
      >
        <Modal.Body>
          <div className="modalBody">
            <div className="modalHeading">
              <img src={whatsLegalLogo} className="websiteLogo" />
            </div>
            {loginActive ? (
              <div className="loginSection">
                {showGuestLimitError && (
                  <div className="guest-limit-text">
                    <p>
                      <div>WhatsLegal ist kostenlos</div>
                      <div>für 3 Fragen pro Tag.</div>
                      {/* <div>Kommen Sie gerne morgen wieder.</div> */}
                    </p>
                    <p>
                      <div style={boldStyle}>Kaufen Sie 5 weitere Fragen für nur 1€</div>
                    </p>
                    <PaypalButton onClick={handlePaypalClick} />

                  </div>
                )}

              </div>
            ) : (
              <div className="signupSection">
                <form onSubmit={signupAction}>
                  <div className="d-flex align-items-center mb-3">
                    <input
                      type="email"
                      className="form-control loginTextbox"
                      id="signupEmail"
                      placeholder="Email"
                      value={signupEmail}
                      onChange={(e) => setSignupEmail(e.target.value)}
                      required />
                    <PersonOutlineRoundedIcon className="eyeIconLogin" />
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form-control loginTextbox"
                      id="signupPassword"
                      placeholder="Password"
                      value={signupPassword}
                      onChange={(e) => setSignupPassword(e.target.value)}
                      required />
                    {passwordVisible ? (
                      <VisibilityOffOutlinedIcon
                        className="eyeIconLogin clickable"
                        onClick={() => setPasswordVisible(!passwordVisible)} />
                    ) : (
                      <VisibilityOutlinedIcon
                        className="eyeIconLogin clickable"
                        onClick={() => setPasswordVisible(!passwordVisible)} />
                    )}
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form-control loginTextbox"
                      id="repeatPassword"
                      placeholder="Repeat password"
                      value={repeatPassword}
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      required />
                    {passwordVisible ? (
                      <VisibilityOffOutlinedIcon
                        className="eyeIconLogin clickable"
                        onClick={() => setPasswordVisible(!passwordVisible)} />
                    ) : (
                      <VisibilityOutlinedIcon
                        className="eyeIconLogin clickable"
                        onClick={() => setPasswordVisible(!passwordVisible)} />
                    )}
                  </div>

                  <div className="form-group form-check mt-3 mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input clickable"
                      id="signupTerms"
                      required />
                    <label
                      htmlFor="signupTerms"
                      className="stayLoggedInLabel clickable"
                    >
                      I agree with <u>Terms and Condition</u>
                    </label>
                  </div>

                  <button
                    className={`signupBtn`}
                    disabled={loginLoading ? true : false}
                    style={{
                      cursor: `${loginLoading ? "not-allowed" : "pointer"}`,
                    }}
                  >
                    {!loginLoading ? "Sign up" : "Signing up..."}
                  </button>

                  <div className="signupLink">
                    <span>Already have an account ? </span>
                    <a
                      className="signup-switch-link"
                      href="#"
                      onClick={() => setLoginActive(true)}
                    >
                      Sign in here
                    </a>
                  </div>
                </form>
                <div style={{ margin: "30px 0" }}></div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showFeedbackModal}
        onHide={() => setShowFeedbackModal(false)}
        id="feedbackModal"
        // backdrop="static"
        centered
        dialogClassName="feedbackModal"
      >
        <Modal.Body>
          <div className="">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img src={getFeedbackIcon()} className="feedbackIcon" />
                <span className="feedbackHeading ms-2">
                  Provide additional feedback
                </span>
              </div>
              <div
                className="clickable"
                onClick={() => setShowFeedbackModal(false)}
              >
                <IoClose size={30} />
              </div>
            </div>
          </div>
          <textarea
            className="form-control feedbackInput my-3"
            rows={4}
            value={feedbackMsg}
            onChange={(e) => setFeedbackMsg(e.target.value)} />
          <div className="d-flex justify-content-end">
            <button
              className="btn feedbackBtn"
              onClick={submitFeedback}
              disabled={feedbackLoading}
            >
              {feedbackLoading ? "Submitting..." : "Submit feedback"}
            </button>
          </div>
        </Modal.Body>

      </Modal>

      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        id="profileModal"
        // backdrop="static"
        centered
        dialogClassName="profileModal"
      >
        <Modal.Body>
          <div className="modalHeading">Profile</div>
          <div className="d-flex align-items-center mb-3">
            <input
              type="email"
              className="form-control loginTextbox"
              id="email"
              placeholder="Email"
              value={userEmail}
              disabled />
            <PersonOutlineRoundedIcon className="eyeIcon" />
          </div>
          <div className="d-flex align-items-center mb-3">
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control loginTextbox"
              id="password"
              placeholder="Password"
              value={userPassword}
              disabled />
            {passwordVisible ? (
              <VisibilityOffOutlinedIcon
                className="eyeIcon clickable"
                onClick={() => setPasswordVisible(!passwordVisible)} />
            ) : (
              <VisibilityOutlinedIcon
                className="eyeIcon clickable"
                onClick={() => setPasswordVisible(!passwordVisible)} />
            )}
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn closeProfileBtn"
              onClick={() => setShowProfileModal(false)}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAddModal}
        onHide={handleClose}
        id="addShowModel"
        centered
        dialogClassName={`feedbackModal add-pop unique-modal ${closing ? 'fade-out' : 'fade-in'}`}
        backdrop="static"
      >
        <Modal.Body>
          <div id="popup-container">
            <div className="timer">{popupTimer}</div>
            {!showCloseButton && <div className="timer">{popupTimer}</div>}
            <div id="popup">
              <a
                rel="sponsored"
                href={addLink1}
                target="_blank"
              >
                <img src={placeHolderAddImage} alt="Advertisement" className="popup-image" loading="lazy" />
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSubscribeModal}
        onHide={() => {
          checkUserIsSubscribed();
          setShowSubscribeModal(false);
        }}
        id="subscriptionModel"
        //backdrop="static"
        centered
        dialogClassName="signupModal"
      >
        <Modal.Body>
          <div className="modalBody">
            <div className="modalHeading">
              <img src={whatsLegalLogo} className="websiteLogo" />
            </div>
            <div className="loginSection">
              <div className="guest-limit-text">
                <p>
                  <div>WhatsLegal is free forever,</div>
                  <div>but just limited to 3 questions a day.</div>
                  <div>Please come back tomorrow</div>
                </p>
                <p>
                  <div>or</div>
                </p>
                <div>Subscribe for just 5€ a month and get 15 questions per day. </div>
                <div>
                  <a
                    href="https://www.whatslegal.ai/free-version"
                    target="_blank"
                  >
                    More info
                  </a>
                </div>
              </div>

              <a className="dropdown-item"
                href={`https://buy.stripe.com/test_bIY4hB6NieRB1qMbII?client_reference_id=${uId}`}
                target="_blank">
                <button
                  type="submit"
                  className="loginBtn"
                  disabled={loginLoading ? true : false}
                  style={{
                    cursor: `${loginLoading ? "not-allowed" : "pointer"}`,
                  }}
                >
                  {!loginLoading ? "Subscribe" : "Subscribing..."}
                </button>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSettingModel}
        onHide={() => {
          setInputSettingCode(settingCode);
          setShowSettingModel(false);
        }}
        id="settingModal"
        // backdrop="static"
        centered
        dialogClassName="feedbackModal"
      >
        <Modal.Body>
          <div className="d-flex align-items-center mb-3">
            <input
              type={settingCodeVisible ? "text" : "password"}
              className="form-control loginTextbox"
              id="settingCode"
              placeholder="Enter Code"
              value={inputSettingCode}
              onChange={(e) => {
                setInputSettingCode(e.target.value);
              }} />
            {settingCodeVisible ? (
              <VisibilityOffOutlinedIcon
                className="eyeIcon clickable"
                onClick={() => setSettingCodeVisible(!settingCodeVisible)} />
            ) : (
              <VisibilityOutlinedIcon
                className="eyeIcon clickable"
                onClick={() => setSettingCodeVisible(!settingCodeVisible)} />
            )}
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn closeProfileBtn"
              onClick={() => {
                setSettingCode(inputSettingCode);
                localStorage.setItem("settingCode", inputSettingCode);
                setShowSettingModel(false);
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );


}


export default ChatBot;

